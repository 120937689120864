import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faLinkedin,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

import { buscaPadariaSlug } from '../services/Votacao';
import { useGlobal } from '../contexts/global';

// import logoUnilever from '../assets/images/logo-unilever.png';
// import logoHellmanns from '../assets/images/logo_hellmanns.png';
// import logoSearaFundoColorido from '../assets/images/logo_seara_fundo_colorido.png';
// import logoNestle from '../assets/images/logo_nestle.png';
// import logoCatupiry from '../assets/images/logo_catupiry.png';
// import logoBunge from '../assets/images/logo_bunge.png';
// import logoSindipan from '../assets/images/logo_sindipan.png';
// import logoFoodToSave from '../assets/images/logo-food-to-save.png';
// import logoBandNewsFm from '../assets/images/logo_bandnewsfm.png';
// import logoNita from '../assets/images/logo-nita.svg';

import '../assets/scss/components/PadariaDetalhes.scss';

const PadariaDetalhes = () => {
  const fase = String(process.env.REACT_APP_FASE);
  const history = useHistory();
  const location = useLocation();
  const modal = useRef();
  const { slug } = useParams();
  const { setShowModalVotacao, setIdPadaria, setNomePadaria } = useGlobal();
  const [padaria, setPadaria] = useState({});

  const back = (e) => {
    e.stopPropagation();
    history.push('/');
  };

  const handleModal = (e) => {
    if (modal.current !== null && modal.current !== undefined) {
      if (!modal.current.contains(e.target)) {
        back(e);
      }
    }
  };

  const handleVoteAgora = () => {
    history.push('/');
    setIdPadaria(padaria.id);
    setNomePadaria(padaria.nome);
    setShowModalVotacao(true);
  };

  useEffect(() => {
    let unmounted = false;

    const laodPadaria = async () => {
      const resp = await buscaPadariaSlug(slug);
      if (!unmounted) {
        setPadaria(resp);
      }
    };
    laodPadaria();

    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
      unmounted = true;
    };
  }, []);

  return (
    <div className="in-details">
      <div className="in-details-modal" ref={modal}>
        <div className="in-details-modal-grid">
          <div className="in-details-modal-grid-sup">
            <img src={padaria.imagem} alt={padaria.imagem} />
          </div>
          <div className="in-details-modal-grid-sub">
            <div className="in-details-modal-grid-sub-lf">
              <div className="in-details-modal-grid-sub-lf-title">
                <p className="title">{padaria.nome}</p>
                <div>
                  <p className="endereco">
                    <strong>Endereço: </strong>
                    {padaria.endereco}
                  </p>
                </div>
              </div>
              <div className="in-details-modal-grid-sub-lf-dcpt">
                <p className="descricao">{padaria.descricao}</p>
              </div>
            </div>
            <div className="in-details-modal-grid-sub-rt">
              {fase === '2' && (
                <div className="in-details-modal-grid-sub-rt-votenow">
                  <button onClick={handleVoteAgora} type="button">
                    Vote agora!
                  </button>
                </div>
              )}
              <div className="in-details-modal-grid-sub-rt-socials">
                <p>Compartilhe com seus amigos</p>
                <div>
                  <FontAwesomeIcon
                    onClick={() => {
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=https://padocariasp.com.br${location.pathname}`,
                        '',
                        'width=300,height=500'
                      );
                      return false;
                    }}
                    icon={faFacebookF}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      window.open(
                        `https://twitter.com/share?url=https://padocariasp.com.br${location.pathname}`,
                        '',
                        'width=600,height=800'
                      );
                      return false;
                    }}
                    icon={faTwitter}
                  />
                  <FontAwesomeIcon
                    onClick={() => {
                      window.open(
                        `https://www.linkedin.com/sharing/share-offsite/?url=https://padocariasp.com.br${location.pathname}`,
                        '',
                        'width=600,height=800'
                      );
                      return false;
                    }}
                    icon={faLinkedin}
                  />
                  <a
                    href={`https://api.whatsapp.com/send?text=https://padocariasp.com.br${location.pathname}`}
                    data-action="share/whatsapp/share"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                </div>
              </div>
            </div>
            <div className="in-footer-patrocinadores">
              {/* <div className="in-partiners-ctn-grid-companies">
                <div className="in-partiners-ctn-grid-companies-item">
                  <p>Patrocínio</p>
                  <div className="patrocinio-grid">
                    <div className="patrocinio-col">
                      <p>Ouro</p>
                      <div className="logos">
                        <a
                          href="https://www.unileverfoodsolutions.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoUnilever}
                            alt="Logo Unilever"
                            width="150"
                          />
                        </a>
                        <a
                          href="https://www.hellmanns.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoHellmanns}
                            alt="Logo Hellmanns"
                            width="150"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="patrocinio-col">
                      <p>Prata</p>
                      <div className="logos">
                        <a
                          href="https://www.seara.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoSearaFundoColorido}
                            alt="Logo Seara"
                            width="80"
                          />
                        </a>
                        <a
                          href="https://www.nestle.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={logoNestle} alt="Logo Nestle" width="80" />
                        </a>
                      </div>
                    </div>
                    <div className="patrocinio-col">
                      <p>Bronze</p>
                      <div className="logos">
                        <a
                          href="https://catupiry.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoCatupiry}
                            alt="Logo Catupiry"
                            width="80"
                          />
                        </a>
                        <a
                          href="https://loja.bungeprofissional.com.br/?utm_source=site-seo&utm_medium=organic&utm_campaign=padocaria&utm_id=padocaria24&utm_content=banner-home-1080x462"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={logoBunge} alt="Logo Bunge" width="80" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="in-partiners-ctn-grid-companies-item">
                  <p>Apoio</p>
                  <div className="logos">
                    <a
                      href="https://sampapao.org.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={logoSindipan} alt="Logo Sindipan" width="80" />
                    </a>
                    <a
                      href="https://foodtosave.com.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={logoFoodToSave}
                        alt="Logo FoodToSave"
                        width="80"
                      />
                    </a>
                  </div>
                </div>

                <div className="in-partiners-ctn-grid-companies-item">
                  <p>
                    Rádio
                    <br />
                    Oficial
                  </p>
                  <div className="logos">
                    <a
                      href="https://www.band.uol.com.br/bandnews-fm"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={logoBandNewsFm}
                        alt="Logo BandNews FM"
                        width="80"
                      />
                    </a>
                  </div>
                </div>

                <div className="in-partiners-ctn-grid-companies-item">
                  <p>
                    Farinha
                    <br />
                    Oficial
                  </p>
                  <div className="logos">
                    <a
                      href="https://nita.com.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={logoNita} alt="Logo Nita" width="80" />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PadariaDetalhes;
