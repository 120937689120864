/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';

import TermosDeUso from '../components/TermosDeUso';
import LGPD from '../components/LGPD';
import PadariaDetalhes from '../components/PadariaDetalhes';
import ModalVotacao from '../components/ModalVotacao';
import { useGlobal } from '../contexts/global';
import Regulamento from '../components/Regulamento';

import { buscaPadarias } from '../services/Votacao';

import '../assets/scss/Home.scss';

import bgBaker from '../assets/images/banner-hellmans.png';
import bgContest from '../assets/images/bg_contest.png';
import bgPadocaria from '../assets/images/bg_padocaria.png';
import bgStep from '../assets/images/bg_step.png';
import iconBrasil from '../assets/images/icon_brasil.png';
import iconCalendar from '../assets/images/icon_calendar.png';
import iconChat from '../assets/images/icon_chat.png';
import iconCheck from '../assets/images/icon_check.png';
import iconFacebook from '../assets/images/icon_facebook.png';
import iconFirst from '../assets/images/icon_first.png';
import iconInstagram from '../assets/images/icon_instagram.png';
import iconSp from '../assets/images/icon_sp.png';
import iconSpBig from '../assets/images/icon_sp_big.png';
import iconSpLove from '../assets/images/icon_sp_love.png';
import iconStep1 from '../assets/images/icon_step_1.png';
import iconStep2 from '../assets/images/icon_step_2.png';
import iconStepAward from '../assets/images/icon_step_award.png';
import iconTrigo from '../assets/images/icon_trigo.png';
import logoNossaUol from '../assets/images/logo_uol.png';
import logoBandNewsFm from '../assets/images/logo_bandnewsfm.png';
import logoOlivasDigital from '../assets/images/logo_olivas_digital.png';
import logoPadocaria from '../assets/images/logo_padocaria.png';
import logoPadocariaSmall from '../assets/images/logo_padocaria_small.png';
import logoNita from '../assets/images/logo-nita.svg';
import logoNestle from '../assets/images/logo_nestle.png';
import logoCatupiry from '../assets/images/logo_catupiry.png';
import logoSearaFundoBranco from '../assets/images/logo_seara_fundo_branco.png';
import logoSearaFundoColorido from '../assets/images/logo_seara_fundo_colorido.png';
import logoZeroCall from '../assets/images/logo-zerocall.png';
import logoSindipan from '../assets/images/logo_sindipan.png';
import logoFoodToSave from '../assets/images/logo-food-to-save.png';
import logoBunge from '../assets/images/logo_bunge.png';
import logoHellmanns from '../assets/images/logo_hellmanns.png';
import logoUnilever from '../assets/images/logo-unilever.png';
import bannerPubliAssai from '../assets/images/banner-publi2.jpg';
import Popup from '../components/PopUp';

import imageCafezinho from '../assets/images/image_cafezinho.png';
import imageChapeiro from '../assets/images/image_chapeiro.png';
import imageDoce from '../assets/images/image_doce.jpg';
import imageFlip from '../assets/images/image_flip.png';
import imageFrios from '../assets/images/image_frios.png';
import imagePaoNaChapa from '../assets/images/image_paonachapa.png';
import imagePaozinho from '../assets/images/image_paozinho.png';
import imageFermentacaoNatural from '../assets/images/image_fermentacao_natural.jpg';
import imagePizza from '../assets/images/image_pizza.png';
import imagePadaria from '../assets/images/image_padaria.png';
import bannerNita from '../assets/images/banner-nita.png';
import bannerCatupiry from '../assets/images/banner-catupiry.png';
import bannerNestle from '../assets/images/banner-nestle.png';
import bannerSeara from '../assets/images/banner-seara.jpg';
import bannerBunge from '../assets/images/banner-bunge.png';
import AdBanner from '../components/AdBanner';

const Home = () => {
  const fase = String(process.env.REACT_APP_FASE);
  const dayCounter = 30 - new Date().getDate();
  const location = useLocation();
  const history = useHistory();
  const [padarias, setPadarias] = useState({});
  const {
    success,
    setSuccess,
    error,
    setError,
    showModalVotacao,
    setShowModalVotacao,
    setIdPadaria,
    setNomePadaria,
    showRegulamento,
    setShowRegulamento,
    zonaAtual,
    setZonaAtual
  } = useGlobal();

  const tab = {
    regiao_central: 'REGIÃO CENTRAL',
    zona_leste: 'ZONA LESTE',
    zona_norte: 'ZONA NORTE',
    zona_oeste: 'ZONA OESTE',
    zona_sul: 'ZONA SUL',
    zp_pao_na_chapa_com_catupiry: 'PÃO NA CHAPA COM CATUPIRY'
  };

  const scroll = () => {
    document.getElementById('bt-votar').style.display = 'none';
    document
      .getElementById('section-votacao')
      .scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollResultados = () => {
    const resultadosSection = document.getElementById('vencedores');
    if (resultadosSection) {
      resultadosSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const showVotacao = (id, nome, zona) => {
    setIdPadaria(id);
    setNomePadaria(nome);
    setZonaAtual(zona);
    setShowModalVotacao(true);
  };

  useEffect(() => {
    let unmounted = false;
    setZonaAtual(tab.regiao_central);
    const laodPadarias = async () => {
      const resp = await buscaPadarias();
      if (!unmounted) {
        setPadarias(resp);
      }
    };

    laodPadarias();

    if (success) {
      Swal.fire({
        timer: 2500,
        icon: 'success',
        showConfirmButton: false,
        html: '<p class="in-home-modal-title">Seu voto foi confirmado com sucesso. Obrigado!</p>',
        didClose: () => {
          setSuccess(false);
          setError(false);
          history.push('/');
        }
      });
    }

    if (error) {
      Swal.fire({
        timer: 2500,
        icon: 'error',
        showConfirmButton: false,
        text: 'Usuário já votou nessa categoria!',
        didClose: () => {
          setSuccess(false);
          setError(false);
        }
      });
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const [isPopupOpen, setPopupOpen] = useState(true);

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const proximaZonaKey = localStorage.getItem('proxima_zona');
    if (proximaZonaKey) {
      setTimeout(() => {
        const padariasElement = document.getElementById('padarias');
        if (padariasElement) {
          padariasElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);

      setTimeout(() => {
        const tabElement = document.querySelector(
          `[data-tab-key="${proximaZonaKey}"]`
        );
        if (tabElement) {
          tabElement.click();
        }
      }, 500);

      localStorage.removeItem('proxima_zona');
    }
  }, [history]);

  return (
    <>
      {/* Modais */}
      {location.pathname === '/termos-de-uso' && <TermosDeUso />}
      {location.pathname === '/lgpd' && <LGPD />}
      {location.pathname.split('/')[1] === 'padaria' && <PadariaDetalhes />}
      {showModalVotacao && <ModalVotacao />}
      {showRegulamento && <Regulamento />}

      {/* Home */}
      <section>
        {(fase === '1' || fase === '2') && (
          <section className="in-votenow" id="bt-votar">
            <span onClick={scroll}>Vote agora!</span>
          </section>
        )}
        <section className="in-init">
          {/* FASE 1 encerrada modal */}
          {/* <Popup isOpen={isPopupOpen} onClose={closePopup} /> */}
          <div className="in-init-bg">
            <img src={bgPadocaria} alt={bgPadocaria} />
          </div>
          <div className="in-init-line">
            <hr />
          </div>
          <div className="in-init-socials">
            <div className="in-init-socials-center">
              <a
                href="https://www.facebook.com/padocariasp.com.br"
                rel="noreferrer"
                target="_blank"
              >
                <img src={iconFacebook} alt={iconFacebook} />
              </a>
              <a
                href="https://www.instagram.com/padocariasp/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={iconInstagram} alt={iconInstagram} />
              </a>
            </div>
          </div>
          <div className="in-init-ctn">
            <div className="in-init-ctn-grid">
              <div className="in-init-ctn-grid-logo">
                <img src={logoPadocaria} alt={logoPadocaria} />
              </div>
              <div className="in-init-ctn-grid-info">
                <div className="in-init-ctn-grid-info-icon">
                  <img src={iconChat} alt={iconChat} />
                </div>
                <div className="in-init-ctn-grid-info-title">
                  <p>Em busca da</p>
                  <h1>
                    Melhor Padaria de
                    <br />
                    <span> São Paulo</span>
                  </h1>
                </div>

                <div className="in-init-ctn-grid-info-subtitle">
                  <p>
                    Você, paulistano, escolha a sua padaria predileta em cada
                    região da cidade.
                    <br />
                    Você, panificador, mobilize sua freguesia!
                  </p>
                </div>
                {(fase === '1' || fase === '2') && (
                  <>
                    <div className="in-init-ctn-grid-info-votenow">
                      <span onClick={scroll}>Vote agora!</span>
                    </div>
                    {/* <div className="in-init-ctn-grid-info-daycounter">
                      <h1>
                        FALTA{dayCounter > 1 ? 'M' : ''}{' '}
                        <span>
                          {dayCounter} DIA{dayCounter > 1 ? 's' : ''}
                        </span>
                      </h1>
                    </div> */}
                  </>
                )}
              </div>
              {/* <div className="in-init-ctn-grid-partiners">
                <div className="in-init-ctn-grid-partiners-item">
                  <p>Patrocínio</p>
                  <div className="patrocinio-grid">
                    <div className="patrocinio-col">
                      <p>Ouro</p>
                      <div className="logos">
                        <a
                          href="https://www.unileverfoodsolutions.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoUnilever}
                            alt="Logo Unilever"
                            width="150"
                          />
                        </a>
                        <a
                          href="https://www.hellmanns.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoHellmanns}
                            alt="Logo Hellmanns"
                            width="150"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="patrocinio-col">
                      <p>Prata</p>
                      <div className="logos">
                        <a
                          href="https://www.seara.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoSearaFundoColorido}
                            alt="Logo Seara"
                            width="80"
                          />
                        </a>
                        <a
                          href="https://www.nestle.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={logoNestle} alt="Logo Nestle" width="80" />
                        </a>
                      </div>
                    </div>
                    <div className="patrocinio-col">
                      <p>Bronze</p>
                      <div className="logos">
                        <a
                          href="https://catupiry.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoCatupiry}
                            alt="Logo Catupiry"
                            width="80"
                          />
                        </a>
                        <a
                          href="https://loja.bungeprofissional.com.br/?utm_source=site-seo&utm_medium=organic&utm_campaign=padocaria&utm_id=padocaria24&utm_content=banner-home-1080x462"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={logoBunge} alt="Logo Bunge" width="80" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="in-init-ctn-grid-partiners-item">
                  <p>Apoio</p>
                  <div className="logos">
                    <a
                      href="https://sampapao.org.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={logoSindipan} alt="Logo Sindipan" width="80" />
                    </a>
                    <a
                      href="https://foodtosave.com.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={logoFoodToSave}
                        alt="Logo FoodToSave"
                        width="80"
                      />
                    </a>
                  </div>
                </div>

                <div className="in-init-ctn-grid-partiners-item">
                  <p>
                    Rádio
                    <br />
                    Oficial
                  </p>
                  <div className="logos">
                    <a
                      href="https://www.band.uol.com.br/bandnews-fm"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={logoBandNewsFm}
                        alt="Logo BandNews FM"
                        width="80"
                      />
                    </a>
                  </div>
                </div>

                <div className="in-init-ctn-grid-partiners-item">
                  <p>
                    Farinha
                    <br />
                    Oficial
                  </p>
                  <div className="logos">
                    <a
                      href="https://nita.com.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={logoNita} alt="Logo Nita" width="80" />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="in-contest">
          <div className="in-contest-bg">
            <img src={bgContest} alt={bgContest} />
          </div>
          <div className="in-contest-line">
            <hr />
          </div>
          <div className="in-contest-ctn">
            <div className="in-contest-ctn-grid">
              <div className="in-contest-ctn-grid-sup">
                <img src={iconFirst} alt={iconFirst} />
                <p>A Premiação</p>
                <p>
                  Por meio do voto popular e de um júri composto por
                  <br />
                  especialistas em gastronomia e por pessoas que conhecem a
                  <br />
                  cidade a fundo, vamos eleger as melhores padarias de
                  <br />
                  São Paulo, em diversas categorias
                </p>
              </div>
              <div className="in-contest-ctn-grid-sub">
                <div className="in-contest-ctn-grid-sub-step">
                  {(fase === '2' ||
                    fase === '1-encerrada' ||
                    fase === '2-encerrada' ||
                    fase === '3') && (
                    <div className="in-contest-ctn-grid-sub-step-container-strip">
                      <div className="in-contest-ctn-grid-sub-step-strip">
                        <h3>Fase encerrada!</h3>
                      </div>
                    </div>
                  )}
                  <div className="in-contest-ctn-grid-sub-step-image">
                    <hr />
                    <img src={iconStep1} alt={iconStep1} />
                  </div>
                  <div className="in-contest-ctn-grid-sub-step-list">
                    <p>Voto popular em cada uma das categorias:</p>
                    <ul>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Café</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Sonho</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Pizza</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Pãozinho</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Pão na chapa</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Pão de Fermentação Natural</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Padaria</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Serviço de frios</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Time de chapeiros</p>
                      </li>
                    </ul>
                    <button type="button">
                      <img src={iconCalendar} alt={iconCalendar} />
                      <span>de 12/09 até 29/09</span>
                    </button>
                  </div>
                </div>
                <div className="in-contest-ctn-grid-sub-step">
                  {(fase === '2' ||
                    fase === '1-encerrada' ||
                    fase === '2-encerrada' ||
                    fase === '3') && (
                    <div className="in-contest-ctn-grid-sub-step-container-strip">
                      <div className="in-contest-ctn-grid-sub-step-strip">
                        <h3>Fase encerrada!</h3>
                      </div>
                    </div>
                  )}
                  <div className="in-contest-ctn-grid-sub-step-image">
                    <hr />
                    <img src={iconStep2} alt={iconStep2} />
                  </div>
                  <div className="in-contest-ctn-grid-sub-step-list">
                    <p>
                      Voto popular e voto do júri.
                      <br />
                      <span>
                        {' '}
                        São 25 padarias finalistas, sendo 5 de cada região:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Zona Norte</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Zona Leste</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Zona Sul</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Zona Oeste</p>
                      </li>
                      <li>
                        <img src={iconCheck} alt={iconCheck} />
                        <p>Região Central</p>
                      </li>
                    </ul>
                    <button type="button">
                      <img src={iconCalendar} alt={iconCalendar} />
                      <span>de 11/10 até 27/10</span>
                    </button>
                  </div>
                </div>
                <div className="in-contest-ctn-grid-sub-step">
                  <div className="in-contest-ctn-grid-sub-step-image">
                    <hr />
                    <img src={iconStepAward} alt={iconStepAward} />
                  </div>
                  <div className="in-contest-ctn-grid-sub-step-award">
                    <p>Premiação</p>
                    <p>
                      Evento para convidados, patrocinadores e imprensa, com a
                      presença de representantes das
                      <span> 25 padarias finalistas</span>, no qual anunciaremos
                      os vencedores de cada categoria, dos prêmios especiais e
                      revelaremos qual é
                    </p>
                    <p>A melhor padaria de São Paulo</p>
                    <button type="button" onClick={scrollResultados}>
                      {/* <img src={iconCalendar} alt={iconCalendar} /> */}
                      <span>Confira o resultado</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="in-baker">
          <a
            href="https://www.hellmanns.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bgBaker} alt={bgBaker} />
          </a>
          <AdBanner />
        </section> */}
        <section className="in-step">
          <div className="in-step-bg">
            <img src={bgStep} alt={bgStep} />
          </div>
          <div className="in-step-ctn">
            <div className="in-step-ctn-grid">
              {/* <div className="in-step-ctn-grid-publicity">
                <p>Publicidade</p>
                <div>
                  <a
                    href="http://www.aniversarioassai.com.br/?utm_source=uol&utm_medium=cpm&utm_campaign=assai_projeto-padocaria_uol_set/out/nov&utm_term=nacional_display-iab_uol-nossa-site-padocaria"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={bannerPubliAssai} alt={bannerPubliAssai} />
                  </a>
                </div>
              </div> */}
              <div className="in-step-ctn-grid-ctt" id="section-votacao">
                <div className="in-step-ctn-grid-ctt-title">
                  <div className="in-step-ctn-grid-ctt-title-main">
                    <img src={iconTrigo} alt={iconTrigo} />
                    {fase !== '3' && <p>Fase {fase.charAt(0)}:</p>}{' '}
                    <p>Resultado</p>
                  </div>
                  <div className="in-step-ctn-grid-ctt-title-info">
                    <p />
                    {(fase.charAt(0) === '1' || fase === '2') && (
                      <p>
                        <strong>
                          Aqui você vota na sua padaria predileta para cada
                          região.
                        </strong>
                        <br />
                        Avalie a qualidade dos serviços e produtos, diversidade
                        dos itens à venda, capricho e bom atendimento.
                        <br />
                        <strong>
                          Atenção para a nova premiação especial: Melhor Pão na
                          Chapa com Catupiry.
                        </strong>{' '}
                        Bora Padocar!
                      </p>
                    )}

                    {fase === '2-breve' && (
                      <p>
                        Muito obrigado a você que participou da fase 1 do
                        Padocaria SP 2022.
                        <br /> E nosso agradecimento e parabéns a todas as
                        padarias que se engajaram e motivaram
                        <br /> seus fregueses a votar. Estamos na fase de
                        apuração e validação dos votos.
                        <br /> Aguarde os finalistas e vote na fase 2, de 6 a 16
                        de outubro.
                      </p>
                    )}
                  </div>
                </div>
                {fase.charAt(0) === '1' && (
                  <div className="in-step-ctn-grid-ctt-cards">
                    <ul>
                      <li>
                        <Link to={fase === '1' && '/votacao/cafezinho'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imageCafezinho} alt={imageCafezinho} />
                              <div>
                                <p>Café</p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Café</p>
                                <p>
                                  Temperatura correta, equilíbrio e frescor são
                                  alguns dos predicados do melhor café de São
                                  Paulo, não importa que seja coado, com
                                  espuminha, pingado, espresso. Indique em qual
                                  padaria está o melhor café de São Paulo.
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to={fase === '1' && '/votacao/doce'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imageDoce} alt={imageDoce} />
                              <div>
                                <p>Sonho</p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Sonho</p>
                                <p>
                                  Supõe-se que a origem deste doce de formato
                                  arrendado - com massa leve de farinha de
                                  trigo, leite, ovos e açúcar, além de recheio
                                  de creme de confeiteiro - seja a Prússia, do
                                  século 18. Teria chegado ao Brasil com os
                                  primeiros imigrantes alemães, no século
                                  seguinte, e se difundido por São Paulo em
                                  meados dos anos 1930, quando tornou-se um
                                  símbolo das padarias da cidade. E assim é até
                                  hoje: padaria que se preze deve preparar um
                                  sonho de respeito. Qual é a padaria que
                                  preparar o melhor sonho, na sua opinião?
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to={fase === '1' && '/votacao/pao-na-chapa'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img
                                src={imagePaoNaChapa}
                                alt={imagePaoNaChapa}
                              />
                              <div>
                                <p>
                                  Pão na <br /> chapa
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Pão na chapa</p>
                                <p>
                                  Mais torradinho ou mais clarinho? Requeijão na
                                  entrada ou na saída? Indique em qual padaria é
                                  servido o mais caprichado pão com manteiga na
                                  chapa de São Paulo
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to={fase === '1' && '/votacao/paozinho'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imagePaozinho} alt={imagePaozinho} />
                              <div>
                                <p>Pãozinho</p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Pãozinho</p>
                                <p>
                                  Mais claro ou mais escurinho. Aerado ou
                                  fofinho. Quando o assunto é o pãozinho, cada
                                  paulistano tem a sua preferência mas nenhum
                                  abre mão da casca crocante e do miolo
                                  saboroso. Vamos apresentar a padaria que assa
                                  o melhor pãozinho de São Paulo.
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to={fase === '1' && '/votacao/servico-de-frios'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imageFrios} alt={imageFrios} />
                              <div>
                                <p>
                                  Serviço <br /> de frios
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Serviço de frios</p>
                                <p>
                                  Fatias finas ou mais espessas. Variedade.
                                  Exposição. Relação entre preço e qualidade.
                                  Embalagem. Eis os elementos que podem ajudam a
                                  apontar a padaria que tem o melhor serviço de
                                  frios de São Paulo.
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            fase === '1' && '/votacao/melhor-time-de-chapeiros'
                          }
                        >
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imageChapeiro} alt={imageChapeiro} />
                              <div>
                                <p>
                                  Time de <br /> chapeiros
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Time de chapeiros</p>
                                <p>
                                  Não basta fazer o melhor pão na chapa nem o
                                  melhor sanduba da cidade. Tem que ser gente
                                  boa, caprichar na limpeza do balcão e ter
                                  vocação para a missão. É por isso que pedimos:
                                  indique a padaria que tem o melhor time de
                                  chapeiros de São Paulo.
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to={fase === '1' && '/votacao/pizza'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imagePizza} alt={imagePizza} />
                              <div>
                                <p>Pizza</p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Pizza</p>
                                <p>
                                  Queremos saber qual é a pizza de São Paulo com
                                  a massa mais gostosa, com um recheio que só de
                                  lembrar dá água na boca. Indique a padaria que
                                  tem aquela pizza que conquistou o seu coração
                                  e paladar.
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={
                            fase === '1' &&
                            '/votacao/melhor-pao-de-fermentacao-natural'
                          }
                        >
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img
                                src={imageFermentacaoNatural}
                                alt={imageFermentacaoNatural}
                              />
                              <div>
                                <p>Melhor Pão de Fermentação Natural</p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Melhor Pão de Fermentação Natural </p>
                                <p>
                                  Seja nas padarias especializadas, seja nas
                                  tradicionais, a fermentação natural é uma
                                  tendência que veio para ficar. Ou melhor, que
                                  voltou para ficar: a fermentação natural da
                                  farinha com a água é um processo de produção
                                  de pão que remonta a 4 mil anos antes de
                                  Cristo. Com a qualidade e a variedade de
                                  farinhas e demais ingredientes de hoje em dia,
                                  é possível assar pães com crostas crocantes,
                                  miolos densos e leves, além de sabores que vão
                                  do ácido ao salgado. Aqui, convidamos a voar
                                  na padaria que melhor prepara esse tipo de
                                  pão'
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to={fase === '1' && '/votacao/melhor-padaria'}>
                          <div className="in-step-ctn-grid-ctt-cards-flip">
                            <div className="in-step-ctn-grid-ctt-cards-flip-front">
                              <img src={imagePadaria} alt={imagePadaria} />
                              <div>
                                <p>Melhor padaria</p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                            <div className="in-step-ctn-grid-ctt-cards-flip-back">
                              <img src={imageFlip} alt={imageFlip} />
                              <div>
                                <p>Melhor padaria</p>
                                <p>
                                  Pãozinho, sanduba, pão na chapa, serviço de
                                  frios, doce, café... Agora que você já deu seu
                                  voto para cada uma dessas categorias, qual é,
                                  na sua opinião, a padaria que tem o melhor
                                  conjunto da obra?
                                </p>
                                {fase === '1' && (
                                  <button type="button">Indique Agora!</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
                {fase === '2' ||
                  (fase === '2-encerrada' && (
                    <div className="in-step-ctn-grid-ctt-cards" id="padarias">
                      <Tabs>
                        <TabList>
                          {Object.keys(padarias).map((key) => (
                            <Tab key={key} data-tab-key={key}>
                              {tab[key]}
                            </Tab>
                          ))}
                        </TabList>
                        {Object.keys(padarias).map((key) => (
                          <TabPanel key={key}>
                            <ul>
                              {padarias[key]?.map((item) => (
                                <li key={item.id}>
                                  <div className="in-step-ctn-grid-ctt-cards-flip">
                                    <div className="in-step-ctn-grid-ctt-cards-flip-front">
                                      <img
                                        src={item.imagem}
                                        alt={item.imagem}
                                      />
                                      <div>
                                        <p>{item.nome}</p>
                                        {fase === '2' && (
                                          <span
                                            onClick={() =>
                                              showVotacao(
                                                item.id,
                                                item.nome,
                                                tab[key]
                                              )
                                            }
                                          >
                                            Vote agora!
                                          </span>
                                        )}
                                        <Link to={`/padaria/${item.slug}`}>
                                          <span>ver detalhes</span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </TabPanel>
                        ))}
                      </Tabs>
                    </div>
                  ))}
                {fase === '3' && (
                  <div className="in-step-ctn-grid-ctt-result" id="vencedores">
                    <div className="container">
                      <div className="ganhador-container">
                        <h3>Café</h3>
                        <ul>
                          <li>1º lugar - Ceci</li>
                          <li>2º lugar - Cepam</li>
                          <li>3º lugar - Estado Luso</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Pão de Fermentação Natural</h3>
                        <ul>
                          <li>1º lugar - Academia do Pão</li>
                          <li>2º lugar - Estado Luso</li>
                          <li>3º lugar - Chez Roberta Petite Boulangerie</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Time de chapeiros</h3>
                        <ul>
                          <li>1º lugar - Pá D'ouro</li>
                          <li>2º lugar - Ceci</li>
                          <li>3º lugar - Cepam</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Pão na chapa</h3>
                        <ul>
                          <li>1º lugar - Estado Luso</li>
                          <li>2º lugar - Condessa</li>
                          <li>3º lugar - Arizona</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Pãozinho</h3>
                        <ul>
                          <li>1º lugar - Arizona</li>
                          <li>2º lugar - Larsol</li>
                          <li>3º lugar - Vera Cruz</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Pizza</h3>
                        <ul>
                          <li>1º lugar - Vera Cruz</li>
                          <li>2º lugar - Europão</li>
                          <li>3º lugar - Ceci</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Serviço de frios</h3>
                        <ul>
                          <li>1º lugar - Cepam</li>
                          <li>2º lugar - Vera Cruz</li>
                          <li>3º lugar - Merci</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Sonho</h3>
                        <ul>
                          <li>1º lugar - Big Pão Express</li>
                          <li>2º lugar - Arizona</li>
                          <li>3º lugar - Europão</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor padaria Zona Oeste</h3>
                        <ul>
                          <li>Forno Fecchio</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor padaria Centro</h3>
                        <ul>
                          <li>Galeria dos Pães</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor padaria Zona Leste</h3>
                        <ul>
                          <li>Vera Cruz</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor padaria Zona Sul</h3>
                        <ul>
                          <li>Ceci</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor padaria Zona Norte</h3>
                        <ul>
                          <li>Arizona</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor Pão na chapa com Catupiry</h3>
                        <ul>
                          <li>1º lugar - Arizona</li>
                          <li>2º lugar - Ceci</li>
                          <li>3º lugar - Estado Luso</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Melhor padaria de São Paulo</h3>
                        <ul>
                          <li>Ceci</li>
                        </ul>
                      </div>
                      <div className="ganhador-container">
                        <h3>Padaria Sustentável</h3>
                        <ul>
                          <li>Casa Nápoles</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <section className="in-banner-container">
            <div>
              <Swiper
                navigation
                modules={[Navigation, Autoplay]}
                className="slider-patrocinadores"
                loop
                autoplay={{
                  delay: 10000
                }}
              >
                <SwiperSlide>
                  <a
                    href="https://www.searafoodsolutions.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={bannerSeara} alt="Banner Seara" />
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <a
                    href="https://www.nestleprofessional.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={bannerNestle} alt="Banner Nestle" />
                  </a>
                </SwiperSlide>
              </Swiper>
            </div>
          </section> */}
        </section>
        <section className="in-whatis">
          <div className="in-whatis-line1">
            <hr />
          </div>
          <div className="in-whatis-ctn">
            <div className="in-whatis-ctn-grid">
              <div className="in-whatis-ctn-grid-lf">
                <div className="in-whatis-ctn-grid-lf-title">
                  <p>Londres tem os pubs. Paris é a capital dos bistrôs.</p>
                  <p>
                    <span>São Paulo é a </span>
                    cidade das padarias.
                  </p>
                </div>
                <div className="in-whatis-ctn-grid-lf-map">
                  <div className="in-whatis-ctn-grid-lf-map-item">
                    <img src={iconBrasil} alt={iconBrasil} />
                    <div>
                      <p>70.000</p>
                      <p>padarias no Brasil</p>
                    </div>
                  </div>
                  <div className="in-whatis-ctn-grid-lf-map-item">
                    <img src={iconSp} alt={iconSp} />
                    <div>
                      <p>15.000</p>
                      <p>padarias no estado de São Paulo</p>
                    </div>
                  </div>
                  <div className="in-whatis-ctn-grid-lf-map-item">
                    <img src={iconSpBig} alt={iconSpBig} />
                    <div>
                      <p>6.000</p>
                      <p>padarias na cidade de São Paulo*</p>
                    </div>
                  </div>
                </div>
                <div className="in-whatis-ctn-grid-lf-font">
                  <p>*Fonte Sampapão</p>
                </div>
              </div>
              <div className="in-whatis-ctn-grid-rt">
                <img src={iconSpLove} alt={iconSpLove} />
                <div className="in-whatis-ctn-grid-rt-info">
                  <p>
                    O que é a <br /> Padocaria SP?
                  </p>
                  <p>
                    Somos um projeto de valorização da história, da cultura, dos
                    produtos, dos serviços e dos profissionais das padarias de
                    São Paulo.
                  </p>
                  <div>
                    <p>Objetivo</p>
                    <p>
                      O Padocaria SP quer levar os própios paulistanos a
                      redescobrirem e elegerem as melhores padarias de São
                      Paulo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="in-whatis-line2">
            <hr />
          </div>
        </section>
        {/* <section className="in-banner-container">
          <div>
            <Swiper
              navigation
              modules={[Navigation, Autoplay]}
              className="slider-patrocinadores"
              loop
              autoplay={{
                delay: 10000
              }}
            >
              <SwiperSlide>
                <a
                  href="https://nita.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={bannerNita} alt="Banner Nita" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://catupiry.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={bannerCatupiry} alt="Banner Catupiry" />
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="https://loja.bungeprofissional.com.br/?utm_source=site-seo&utm_medium=organic&utm_campaign=padocaria&utm_id=padocaria24&utm_content=banner-home-1080x462"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={bannerBunge} alt="Banner Bunge" />
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </section> */}
        <section>
          <iframe
            className="in-feed-instagram"
            src="https://padocariasp.com.br/iframe-instagram/"
            title="Feed Instragram"
          />
        </section>
        <section className="in-iframe">
          <div className="in-iframe-grid">
            <div className="in-iframe-grid-sup">
              <img src={iconChat} alt={iconChat} />
              <a
                href="https://www.instagram.com/padocariasp/"
                rel="noreferrer"
                target="_blank"
              >
                @padocariasp
              </a>
            </div>
            {/* <div className="in-iframe-grid-sub">
          </div> */}
          </div>
        </section>
        <section className="in-partiners">
          <div className="in-partiners-ctn">
            <div className="in-partiners-ctn-grid">
              <div className="in-partiners-ctn-grid-logo">
                <img src={logoPadocariaSmall} alt={logoPadocariaSmall} />
              </div>
              {/* <div className="in-partiners-ctn-grid-companies">
                <div className="in-partiners-ctn-grid-companies-item">
                  <p>Patrocínio</p>
                  <div className="patrocinio-grid">
                    <div className="patrocinio-col">
                      <p>Ouro</p>
                      <div className="logos">
                        <a
                          href="https://www.unileverfoodsolutions.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoUnilever}
                            alt="Logo Unilever"
                            width="150"
                          />
                        </a>
                        <a
                          href="https://www.hellmanns.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoHellmanns}
                            alt="Logo Hellmanns"
                            width="150"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="patrocinio-col">
                      <p>Prata</p>
                      <div className="logos">
                        <a
                          href="https://www.seara.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoSearaFundoColorido}
                            alt="Logo Seara"
                            width="80"
                          />
                        </a>
                        <a
                          href="https://www.nestle.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={logoNestle} alt="Logo Nestle" width="80" />
                        </a>
                      </div>
                    </div>
                    <div className="patrocinio-col">
                      <p>Bronze</p>
                      <div className="logos">
                        <a
                          href="https://catupiry.com.br/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img
                            src={logoCatupiry}
                            alt="Logo Catupiry"
                            width="80"
                          />
                        </a>
                        <a
                          href="https://loja.bungeprofissional.com.br/?utm_source=site-seo&utm_medium=organic&utm_campaign=padocaria&utm_id=padocaria24&utm_content=banner-home-1080x462"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={logoBunge} alt="Logo Bunge" width="80" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="in-partiners-ctn-grid-companies-item">
                  <p>Apoio</p>
                  <div className="logos">
                    <a
                      href="https://sampapao.org.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={logoSindipan} alt="Logo Sindipan" width="80" />
                    </a>
                    <a
                      href="https://foodtosave.com.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={logoFoodToSave}
                        alt="Logo FoodToSave"
                        width="80"
                      />
                    </a>
                  </div>
                </div>

                <div className="in-partiners-ctn-grid-companies-item">
                  <p>
                    Rádio
                    <br />
                    Oficial
                  </p>
                  <div className="logos">
                    <a
                      href="https://www.band.uol.com.br/bandnews-fm"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={logoBandNewsFm}
                        alt="Logo BandNews FM"
                        width="80"
                      />
                    </a>
                  </div>
                </div>

                <div className="in-partiners-ctn-grid-companies-item">
                  <p>
                    Farinha
                    <br />
                    Oficial
                  </p>
                  <div className="logos">
                    <a
                      href="https://nita.com.br/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={logoNita} alt="Logo Nita" width="80" />
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="in-footer">
          <div className="in-footer-ctn">
            <div className="in-footer-ctn-grid">
              <p>
                Copyright - 2024 | Todos os direitos reservados -{' '}
                <Link to="/lgpd">Política de privacidade</Link> |{' '}
                <Link to="/termos-de-uso">Termos de uso</Link> |{' '}
                <span onClick={() => setShowRegulamento(true)}>
                  Regulamento
                </span>
              </p>
              <a
                href="https://www.olivas.digital/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={logoOlivasDigital} alt={logoOlivasDigital} />
              </a>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Home;
